import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import dynamic from 'next/dynamic';
import ArrowDown from '@marty-js/design/src/icons/arrowDown';
import { HeaderLinkLevel1 } from '../../utils/headerFormatter';
import { SecondaryNavItems } from './secondary-nav-items';

const NavIcon = dynamic(() => import('@marty-js/design/src/atoms/material-symbol'));

const NavBar = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavItem = styled.li`
  border-bottom: 1px solid #dfe3ea;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
  line-height: 50px;
  color: var(--title-foreground);

  &.emphasize {
    font-weight: 600;
  }

  &:hover {
    color: var(--theme-color-primary);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: column;
      justify-items: flex-start;
      padding: 0 10px;
      margin-bottom: 0px;
      top: 0px;

      > * {
        display: block;
        line-height: 50px;
      }
    `,
  )}
`;

const NavDrop = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  max-height: 0;
  overflow: hidden;
  text-transform: initial;

  &.mod-active {
    max-height: 1000px;
    transition: max-height 0.3s;
    margin: 0 0 var(--spacer-s);
  }

  &:first-of-type li {
    margin-left: 0;
  }
`;

const NavNoLink = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  cursor: default;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Collapse = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: var(--spacer-s) var(--spacer-xs);
  transition: transform 0.3s;

  &.mod-active {
    transform: rotate(180deg);
  }
`;

interface Props {
  navLinks: HeaderLinkLevel1[];
  onClick?: () => void;
}

interface DropdownProps {
  [key: number]: boolean;
}

export const PrimaryNavItems: React.FC<Props> = ({ navLinks, onClick }: Props) => {
  const [isCurrentUrlInDownload, setIsCurrentUrlInDownload] = React.useState(false);
  const [dropdownsOpen, setDropdownOpen] = useState<DropdownProps>({});
  const LinkComponent = useLinkComponent();

  const downloadRegex = useMemo<RegExp>(() => /telecharger/i, []);

  useEffect(() => {
    setIsCurrentUrlInDownload(downloadRegex.test(window.location.href));
  }, [downloadRegex]);

  const toggleDropdown = (element: any, navItem: number) => {
    dropdownsOpen[navItem] = !dropdownsOpen[navItem] ?? true;
    setDropdownOpen({ ...dropdownsOpen });
  };

  return (
    <NavBar>
      {navLinks.map((navItem) => (
        <NavItem
          key={navItem.order}
          className={
            navItem?.emphasize || (isCurrentUrlInDownload && downloadRegex.test(navItem.url)) ? 'emphasize' : ''
          }
        >
          {navItem.url ? (
            <Dropdown>
              <LinkComponent href={navItem.url} onClick={onClick}>
                {navItem.iconSlug && <NavIcon symbol={navItem.iconSlug} />}
                {navItem.title}
              </LinkComponent>
              {navItem.subLinks && (
                <Collapse
                  onClick={(e: any) => toggleDropdown(e, navItem.order)}
                  className={dropdownsOpen[navItem.order] ? 'mod-active' : ''}
                >
                  <ArrowDown height={12} width={12} color="var(--theme-color-title)" />
                </Collapse>
              )}
            </Dropdown>
          ) : (
            <Dropdown>
              <NavNoLink>
                {navItem.iconSlug && <NavIcon symbol={navItem.iconSlug} />}
                {navItem.title}
              </NavNoLink>
              {navItem.subLinks && (
                <Collapse
                  onClick={(e: any) => toggleDropdown(e, navItem.order)}
                  className={dropdownsOpen[navItem.order] ? 'mod-active' : ''}
                >
                  {navItem.subLinks && <ArrowDown height={12} width={12} color="var(--theme-color-title)" />}
                </Collapse>
              )}
            </Dropdown>
          )}
          {navItem.subLinks && (
            <NavDrop className={dropdownsOpen[navItem.order] ? 'mod-active' : ''}>
              <SecondaryNavItems columns={navItem.subLinks} />
            </NavDrop>
          )}
        </NavItem>
      ))}
    </NavBar>
  );
};
