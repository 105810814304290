import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import { EventType } from '../atoms/types';

const StyledCta = styled.div`
  background: var(--theme-palette-red);
  border-radius: 6px;
  border: solid 2px var(--theme-palette-red);
  cursor: pointer;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background: var(--theme-palette-dark-red);
    border: solid 2px var(--theme-palette-dark-red);
  }

  > a,
  > span {
    color: var(--theme-palette-white);
    display: block;
    padding: 10px 13px;
    font-size: 18px;
    line-height: 1.62;
    text-decoration: none;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        padding: 8px 10px;
        font-size: 16px;
      `,
    )}
  }

  &.mod-inline {
    display: inline-block;
  }
  &.mod-large {
    padding-left: var(--spacer-l);
    padding-right: var(--spacer-l);
    display: inline-flex;
  }

  &.mod-sm a,
  &.mod-sm span {
    font-size: 16px;
    padding: 8px 10px;
  }

  &.mod-primary-font {
    font-family: var(--theme-typography-primary-font);
  }

  &.mod-green {
    background-color: var(--theme-palette-green);
    border: solid 2px var(--theme-palette-green);

    &:hover {
      background-color: var(--theme-palette-dark-green);
      border: solid 2px var(--theme-palette-dark-green);
    }
  }

  &.mod-red {
    background-color: var(--theme-palette-red);
    border: solid 2px var(--theme-palette-red);
  }

  &.mod-blue {
    background-color: var(--theme-palette-blue);
    border: solid 2px var(--theme-palette-blue);

    svg {
      color: var(--theme-palette-blue);
      margin: 0 0 0 10px;
    }

    &:hover {
      background-color: var(--theme-palette-dark-blue);
      border: solid 2px var(--theme-palette-dark-blue);

      svg path {
        fill: var(--theme-palette-white);
      }
    }

    &.mod-empty {
      background: none;
      border: solid 2px var(--theme-palette-blue);
      color: var(--text-color-alt);

      a,
      span {
        color: var(--text-color-alt);
      }

      &:hover {
        background-color: var(--theme-palette-dark-grey-blue);

        a {
          color: white;
        }
      }
    }
  }
`;

export type CtaProps = {
  className?: string;
  title?: string;
  href?: string;
  rel?: string[];
  referer?: string;
  openInNewWindow?: boolean;
  stylized?: boolean;
  onClick?: any;
  arrow?: boolean;
  event?: EventType;
  inline?: boolean;
  noLink?: boolean;
};

export const Cta = ({ ...props }: React.PropsWithChildren<CtaProps>) => {
  const LinkComponent = useLinkComponent();
  const { className, children, noLink } = props;
  const [isLink, setIsLink] = useState<boolean>(!noLink);
  const hover = useCallback(() => (isLink ? null : setIsLink(true)), [isLink, setIsLink]);
  props.className = '';

  return (
    <StyledCta onMouseEnter={hover} className={classNames(className, { 'mod-inline': props.inline })}>
      {isLink ? <LinkComponent {...props} /> : <span>{children}</span>}
    </StyledCta>
  );
};
