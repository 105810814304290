import React from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { HeaderLinkLevel2 } from '../../utils/headerFormatter';

const NavIcon = dynamic(() => import('@marty-js/design/src/atoms/material-symbol'));

const DropDownMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: nowrap;
  list-style: none;
  font-weight: initial;

  &:first-of-type li {
    margin-left: 0;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
      justify-content: flex-start;
      white-space: nowrap;
      margin-top: 16px;
      margin-bottom: 16px;
    `,
  )};
`;

const DropDownColumn = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin-right: 40px;
      white-space: nowrap;
    `,
  )};
`;

const SecondNavItem = styled.li`
  color: var(--theme-color-title);
  font-size: 13px;
  line-height: 29px;
  max-height: 30px;
  padding: 2px;
  margin-left: 40px;

  a {
    display: block;

    &:hover {
      color: var(--theme-color-primary);
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border-bottom: 2px solid transparent;
      font-size: 1em;

      &:first-of-type {
        padding-top: 0;
      }
    `,
  )};
`;

const SecondNavItemUl = styled.ul`
  list-style: none;
  text-align: left;

  li {
    color: var(--text-color-alt);
    line-height: 29px;
    font-size: 13px;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        font-size: 15px;
      `,
    )};

    &:first-child {
      font-size: 14px;
      font-weight: 600;

      ${mq.lte(
        DEVICE_SIZE.LARGE,
        css`
          font-size: 16px;
        `,
      )};
    }
  }
`;

interface Props {
  columns: HeaderLinkLevel2[][];
  onClick?: () => void;
}

export const SecondarySubNav: React.FC<Props> = ({ columns, onClick }: Props) => {
  const LinkComponent = useLinkComponent();

  return (
    <DropDownMenu>
      {columns.map((column: HeaderLinkLevel2[], index) => (
        <DropDownColumn key={index}>
          <SecondNavItemUl>
            {column.map((link: HeaderLinkLevel2) => (
              <SecondNavItem key={link.title}>
                {link.url ? (
                  <LinkComponent href={link.url} onClick={onClick}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </LinkComponent>
                ) : (
                  <span tabIndex={0} role="button" onClick={onClick} onKeyDown={null}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </span>
                )}
              </SecondNavItem>
            ))}
          </SecondNavItemUl>
        </DropDownColumn>
      ))}
    </DropDownMenu>
  );
};
