import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { AffiliateLink, Link } from '@marty-js/design/src/atoms/link';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';

interface Props {
  title?: string;
  shortTitle?: string;
  url?: string;
}

const PromotionalBannerContainer = styled.div`
  display: block;
  background-color: var(--background-color-sub);
  padding: 10px;
  // background-color: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : props.theme.palette.blue)};
  // padding: 6px;
  // position: relative;
  text-align: center;
  margin: 0;
  z-index: 1;
`;

const Title = styled.div`
  display: none;
  font-weight: bold;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      transition: color 0.3s;

      &:hover {
        color: var(--theme-color-primary);
      }
    `,
  )}
`;

const ShortTitle = styled.div`
  display: block;
  font-weight: bold;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )};
`;

export const PromotionalBanner: React.FC<Props> = ({ title, shortTitle, url }: Props) => {
  const LinkComponent = useLinkComponent();
  const [trackEvent] = useTrackEvent();

  const isExternalLink = useMemo(() => {
    const patternIntWithNDD = /^https?:\/\/(?:^.)*\.clubic\.com\//;
    const patternIntWithoutNDD = /^\//;

    return !(patternIntWithNDD.test(url) || patternIntWithoutNDD.test(url));
  }, [url]);

  const handleCTAClick = useCallback(() => {
    const userToken = localStorage.getItem('actionToken');
    trackEvent({
      variables: {
        userToken,
        eventCategory: 'Sponso',
        eventName: 'click_event_header',
        actionName: 'click_event_header',
        eventValue: title,
        customDimensions: [],
        customVariables: [],
        url: document.location.href,
      },
    });
  }, [title, trackEvent]);

  const link = useMemo(
    () => ({
      href: url,
      as: isExternalLink ? AffiliateLink : Link,
      onClick: handleCTAClick,
    }),
    [handleCTAClick, isExternalLink, url],
  );

  return (
    <PromotionalBannerContainer id="top-banner">
      <LinkComponent {...link}>
        <Title>{title}</Title>
        <ShortTitle>{shortTitle}</ShortTitle>
      </LinkComponent>
    </PromotionalBannerContainer>
  );
};
