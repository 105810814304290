import React from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { HeaderLinkLevel2 } from '../../utils/headerFormatter';

const NavIcon = dynamic(() => import('@marty-js/design/src/atoms/material-symbol'));

const DropDownColumn = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-left: solid 1px var(--theme-palette-dark-grey);
  padding: 0 0 0 var(--spacer-xs);
`;

const SecondNavItem = styled.li`
  color: var(--theme-color-title);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.62;
  padding: 2px;
  margin-left: 40px;

  a {
    display: block;

    &:hover {
      color: var(--theme-color-primary);
    }
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 17px;
      padding: 4px;
    `,
  )}
`;

const SecondNavItemUl = styled.ul`
  list-style: none;
`;

interface Props {
  columns: HeaderLinkLevel2[][];
  onClick?: () => void;
}

export const SecondaryNavItems: React.FC<Props> = ({ columns, onClick }: Props) => {
  const LinkComponent = useLinkComponent();

  return (
    <>
      {columns.map((column: HeaderLinkLevel2[], index) => (
        <DropDownColumn key={index}>
          <SecondNavItemUl>
            {column.map((link: HeaderLinkLevel2) => (
              <SecondNavItem key={link.title}>
                {link.url ? (
                  <LinkComponent href={link.url} onClick={onClick}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </LinkComponent>
                ) : (
                  <span tabIndex={0} role="button" onClick={onClick} onKeyDown={null}>
                    {link.iconSlug && <NavIcon symbol={link.iconSlug} />}
                    {link.title}
                  </span>
                )}
              </SecondNavItem>
            ))}
          </SecondNavItemUl>
        </DropDownColumn>
      ))}
    </>
  );
};
