import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import dynamic from 'next/dynamic';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { useSsoPayload } from '@marty-js/api-sdk/services/sso';
import { useSdkConfig } from '../../utils/config';

const UserBorderedIcon = dynamic(() => import('@marty-js/design/src/icons/userBordered'));
const UserIcon = dynamic(() => import('@marty-js/design/src/icons/user'));
const ForumIcon = dynamic(() => import('@marty-js/design/src/icons/forum'));

const ShutdownIcon = dynamic(() => import('@marty-js/design/src/icons/material-symbols/shutdown'));

const AccountButtonHref = styled.a`
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  font-size: 12px;
  gap: var(--spacer-s);
  padding: var(--spacer-xs);
  text-decoration: none;
  transition: color 0.3s;

  &:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:hover {
    color: var(--theme-color-primary);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background: var(--theme-color-background);
      border-bottom: solid 1px var(--theme-color-medium);
    `,
  )}
`;

const AccountButton = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: var(--spacer-xs);
  font-weight: 600;
  max-height: 45px;
  white-space: nowrap;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background: var(--theme-background-contrast-background-color);
      border-radius: 6px;
      padding: var(--spacer-s);
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      svg {
        width: 26px;
        height: auto;
      }

      path {
        fill: white;
      }
    `,
  )}
`;

const AccountHref = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: var(--spacer-xs);
  font-weight: 600;
  max-height: 45px;
  white-space: nowrap;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      background: var(--theme-background-contrast-background-color);
      border-radius: 6px;
      padding: var(--spacer-s);
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      svg {
        width: 26px;
        height: auto;
      }

      path {
        fill: white;
      }
    `,
  )}
`;

const Label = styled.span`
  line-height: 24px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )};
`;

const HeaderProfile = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const AvatarImg = styled.img`
  border-radius: 25px;
`;

const AccountDropdown = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  position: absolute;
  top: calc(-100%);
  transition: opacity 0.1s, top 0.1s;
  width: 100%;

  &:hover {
    opacity: 1;
    top: 100%;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const AccountMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;

  &:hover ${AccountDropdown} {
    opacity: 1;
    top: 100%;
  }
`;

interface Props {
  icon?: string;
  username?: string;
}

export function UserProfile({ icon, username }: React.PropsWithChildren<Props>) {
  const t = useTranslation();
  const [ssoPayload] = useSsoPayload();
  const sdkConfig = useSdkConfig();

  const [urlSso, setUrlSso] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const redirect = `${sdkConfig.baseUrl}/login`;
      const referer = document.referrer;
      const payload = await ssoPayload({
        variables: {
          referer,
          return_sso_url: redirect,
        },
      });
      const url = `${sdkConfig.ssoUrl}api/external_apps/connect/clubic?sso=${payload?.data?.sso.sso}&sig=${payload?.data?.sso.sig}`;
      localStorage.setItem('ssoUrl', url);

      setUrlSso(url);
    };

    fetchData();
  }, [sdkConfig.baseUrl, sdkConfig.ssoUrl, ssoPayload]);

  const checkPayload = async () => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    localStorage.setItem('urlBeforeLogin', document.documentURI);
    window.open(urlSso, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== sdkConfig.baseUrl) {
        return;
      }

      if (event.data.type === 'userLoggedIn') {
        window.location.reload();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [sdkConfig.baseUrl]);

  return (
    <HeaderProfile>
      <AccountMenu>
        {icon ? (
          <AccountHref href="https://forum.clubic.com/" title={t('header.account')}>
            <AvatarImg src={`https://forum.clubic.com/${icon}`} height={24} alt="username" />
            <Label>{username}</Label>
          </AccountHref>
        ) : (
          <AccountButton onClick={() => checkPayload()} title={t('header.account')}>
            <UserBorderedIcon width={18} height={18} color="var(--text-color)" />
            <Label>{t('header.login')}</Label>
          </AccountButton>
        )}
        {username && (
          <AccountDropdown>
            <AccountButtonHref href="https://connect.clubic.com/user/home" title={t('header.profile')}>
              <UserIcon width={18} height={18} color="var(--text-color)" />
              <Label>{t('header.profile')}</Label>
            </AccountButtonHref>
            <AccountButtonHref href="https://forum.clubic.com/" title={t('header.forum')}>
              <ForumIcon width={18} height={18} color="var(--text-color)" />
              <Label>{t('header.forum')}</Label>
            </AccountButtonHref>
            <AccountButtonHref href="https://connect.clubic.com/user/logout" title={t('header.logout')}>
              <ShutdownIcon width={18} height={18} color="var(--text-color)" />
              <Label>{t('header.logout')}</Label>
            </AccountButtonHref>
          </AccountDropdown>
        )}
      </AccountMenu>
    </HeaderProfile>
  );
}
